.sidebar.light{
    background: white !important;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    .navbar .navbar-nav .nav-link:hover,
    .navbar .navbar-nav .nav-link.active {
        color: #ffffff;
        background: #516fec;
        border-color: #00e2ff;
    }
}
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 260px;
    height: 100vh;
    overflow-y: auto;
    background: #191C24;
    transition: 0.5s;
    z-index: 999;
}


@media (min-width: 992px) {
    .sidebar {
        margin-left: 0;
    }

    .sidebar.open {
        margin-left: -250px;
    }
}

@media (max-width: 991.98px) {
    .sidebar {
        margin-left: -250px;
    }

    .sidebar.open {
        margin-left: 0;
    }
}

/*** Navbar ***/
.ocpl-crm-logo{
    font-family: 'Orbitron',
        sans-serif;
}
.sidebar .navbar .navbar-brand h3 {
    font-size: 1.6rem;
    font-family: "Roboto", sans-serif;
}
.sidebar .navbar .navbar-nav .nav-link {
    padding: 7px 20px;
    color: #6C7293;
    font-weight: 500;
    border-left: 3px solid #191C24;
    border-radius: 0 30px 30px 0;
    outline: none;
}

.sidebar .navbar .navbar-nav .nav-link:hover,
.sidebar .navbar .navbar-nav .nav-link.active {
    color: #514ce8;
    background: #000000;
    border-color: #514ce8;
}

.sidebar .navbar .navbar-nav .nav-link i {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #000000;
    border-radius: 40px;
}

.sidebar .navbar .navbar-nav .nav-link:hover i,
.sidebar .navbar .navbar-nav .nav-link.active i {
    background: #191C24;
}
