.canvas{
        background: white;
        height: 95vh;
        width: 100vw;
    
        /* position: fixed;
        z-index: 1;
        top: 0;
        left: 0px;
         */
        /* cursor: pointer; */
}