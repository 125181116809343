.user-dashboard {
  background-color: #030b15;
  padding-top: 180px;
  @media (max-width:992px) {
    padding-top: 250px;
  }

  .description {
    color: white;
    p {
      color: white;
    }
  }
  .box {
    background-color: #262d47;
    padding: 1.7rem;
    height: 400px;
    box-shadow: rgb(29, 29, 29, 40%) 0px 0px 16px;
    border-radius: 20px;
  }
  .profile-pic {
    height: 6rem;
    border-radius: 4px;
    margin-right: 1.5rem;
  }
  .contact-info {
    margin-top: 1rem;
    display: flex;
    align-items: center;

    .email {
      color: white;
      margin: 0 0 0 0.5rem;
    }
    .mobile {
      color: white;
      margin: 0 0 0 0.5rem;
    }
    span {
      height: 30px;
      border-left: 2px solid rgb(1, 1, 185);
      margin: 0 1.3rem;
      border-image: linear-gradient(to top, rgb(123, 25, 236), rgb(0, 0, 156));
      border-image-slice: 1;
    }
  }
  .remarks{
    color:white;
  }
  .data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .payment-status {
      color:white;
      border-radius: 12px;
    }
  }
  .payment-history {
    padding: 0 1rem;
    overflow-y: scroll;
    height: 17rem;
  }
  .payment-history::-webkit-scrollbar {
    display: none;
  }
  &.light {
    background-color: rgb(218, 220, 222);
    .description {
      color: black !important;
      p {
        color: black;
      }
    }
    .box {
      background-color: rgb(218, 220, 222);
    }
    .contact-info{
        .email{
            color:black;
        }
        .mobile{
            color:black;    
        }
    }
    .remarks{
        color:black;
      }
  }
}
.empty-userDashboard {

  background-color: #030b15;
  min-height: 100vh;
  padding-top: 180px;
  @media (max-width:992px) {
    padding-top: 250px;
  }
  .message {
    background-color: #262d47;
    margin-top: 5rem;
    text-align: center;
    border-radius: 20px;
    box-shadow: rgb(29, 29, 29, 40%) 0px 0px 16px;
      img {
        width: 20%;
      }
  }
  &.light{
    background-color: rgb(218, 220, 222);
    .message{
      background-color: rgb(218, 220, 222);
    }
  }


}
// .modal-content {
//   background-color: #262d47;
//   text-align: center;
// }
// .modal-header {
//   padding: 0.7rem !important;
//   background-color: #121730;
// }
// .btn-close {
//   padding: 0 !important;
// }
// .modal-body {
//   img {
//     width: 15rem;
//   }
//   h4 {
//     color: white;
//   }
// }

.row {
  --bs-gutter-y: 1.5rem !important;
}
