.banner-bg {
  background: linear-gradient(
    0.23deg,
    #030b15 5.68%,
    rgba(3, 11, 21, 0.42) 81.9%
  );
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  top: 14%;
  position: absolute;
  width: 100%;
  // z-index: 100;
  @media screen and (max-width: 992px) {
    margin-top: 100px;
  }

  &.light{
    background: rgb(218, 220, 222);

  }
}

.banner-content .title {
  font-size: 50px;
  margin-top: 80px;
  letter-spacing: -0.01em;
  line-height: 1.4;
  @media (max-width: 992px) {
    font-size: 35px;
  }
  @media (max-width: 992px) {
    font-size: 30px;
    margin-top: 60px;
  }
}
.title {
  &.light {
    color: black;
  }
}
.banner-content .title span {
  color: #00c4f4;
}

.banner-countdown-wrap {
  .title {
    font-size: 26px;
    letter-spacing: -0.01em;
    margin-bottom: 15px;
    margin-top: 300px;
    @media (max-width: 1024px) {
      margin-top: 250px;
    }
    @media (max-width: 992px) {
      margin-top: 200px;
    }
    @media (max-width: 450px) {
      margin-top: 180px;
    }
    @media (max-width: 350px) {
      margin-top: 100px;
    }
  }
  .coming-time {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media (max-width: 450px) {
      grid-template-columns: 1fr 1fr;
    }
    .time-count {
      align-items: center;
      background: #0b1d33;
      border: 1px solid hsla(0, 0%, 100%, 0.04);
      border-radius: 5px;
      color: #a4b4c3;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 600;
      justify-content: center;
      line-height: 1;
      margin: 20px 17.5px 0;
      min-height: 96px;
      min-width: 162px;
      padding: 10px;
      position: relative;
      text-transform: capitalize;
      @media (max-width: 768px) {
        margin: 5px;
      }
      @media (max-width: 450px) {
        margin: 5px;
        padding: 2px;
        min-height: 56px;
        min-width: 102px;
      }
      span {
        color: #00c4f4;
        display: block;
        font-family: Poppins, sans-serif;
        font-size: 35px;
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 12px;
      }
      &.light {
        background: rgb(207, 208, 212);
        box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
          rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
      }
    }
  }
}
.text {
  &.light {
    color: #000;
  }
}
.banner-countdown-wrap .coming-time .time-count.hour span {
  color: #ff9700;
}
.banner-countdown-wrap .coming-time .time-count.min span {
  color: #ff1d45;
}
.banner-countdown-wrap .coming-time .time-count.sec span {
  color: #12d176;
}
