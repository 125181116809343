.content.light{
    background: #e6e7eb !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    
    .navbar .sidebar-toggler-btn{
        background: rgb(244, 244, 244);
        border: none;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }
    input.form-control{
        background: white !important;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    .navbar .navbar-nav .nav-link i{
        background: rgb(244, 244, 244);
            border: none;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
            // color: rgb(79, 63, 171);
    }
    .navbar-nav .nav-link span{
            color: black;
        }
    .navbar-nav.dropdown-menu.dropdown-item h6{
        color: black !important;
    }
    .navbar .dropdown-item:hover,
    .navbar .dropdown-item.active {
        background:#e6e7eb;
    }
}


.navbar-content.light{
    background: white !important;
}

.content {
    margin-left: 250px;
    background: #000000;
    transition: 0.5s;
}


@media (min-width: 992px) {
    .content {
        width: calc(100% - 250px);
    }

    .content.open {
        width: 100%;
        margin-left: 0;
    }
}

@media (max-width: 991.98px) {
    .content {
        width: 100%;
        margin-left: 0;
    }
}

.content .navbar .navbar-nav .nav-link {
    margin-left: 25px;
    padding: 12px 0;
    color: #6C7293;
    outline: none;
    @media (max-width: 992px) {
        margin-left: 10px;
    }
}

.content .navbar .navbar-nav .nav-link:hover,
.content .navbar .navbar-nav .nav-link.active {
    color: #514ce8;
}

.content .navbar .sidebar-toggler-btn{
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #000000;
    border-radius: 50%;
    i{
        color: #514ce8;;
    }
}
.content .navbar .navbar-nav .nav-link i {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #000000;
    border-radius: 40px;
}

.content .navbar .dropdown-item {
    color: #6C7293;
}

.content .navbar .dropdown-item:hover,
.content .navbar .dropdown-item.active {
    background: #000000;
    color: white !important;
}

.content .navbar .dropdown-toggle::after {
    margin-left: 6px;
    vertical-align: middle;
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    transition: .5s;
}

.content .navbar .dropdown-toggle[aria-expanded=true]::after {
    transform: rotate(-180deg);
}

@media (max-width: 575.98px) {
    .content .navbar .navbar-nav .nav-link {
        margin-left: 15px;
    }
}
.toggle-btn {
    padding-top: 1px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 1.5rem;

    // p {
    //     font-size: 11.5px;
    //     font-weight: 700;
    //     color: black;
    // }

    @media screen and (max-width: 900px) {
        padding-top: 1.2rem;
    }

    label {
        margin-left: 3px;
        width: 40px;
        height: 18px;
        position: relative;
        display: block;
        background: #ebebeb;
        border-radius: 200px;
        box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4), inset 0px -5px 15px rgba(255, 255, 255, 0);
        cursor: pointer;
        transition: .3s;

        @media screen and (max-width: 900px) {
            width: 50px;
        }
    }

    label::after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        top: 1.5px;
        left: 0.5px;
        background: linear-gradient(180deg, #3862e1, #574fe9 49%, #1212c8);
        border-radius: 180px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.115);
        transition: .3s;

    }

    input {
        width: 0;
        height: 0;
        visibility: hidden;
    }

    input:checked+label {
        background: #383838;
    }

    input:checked+label::after {
        left: 40px;
        transform: translateX(-100%);
        background: linear-gradient(180deg, #365582, #0066fb);

        @media screen and (max-width: 900px) {
            left: 48px;
        }
    }

    label:active::after {
        width: 20px;
    }
}