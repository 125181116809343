
.apply-loan {
    min-height: 100vh;
    background-image: url("https://wordpress-theme.spider-themes.net/banca/wp-content/uploads/2021/10/bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    position: relative;
  
  
  .loan-home-title{
    font-size: 40px;
    font-weight: 600;
    color: white  ;
    // margin-top:10px;
    padding-top: 250px;
    position: relative;
    @media (max-width:992px) {
      padding-top: 310px;
    }
  
    
  }
  .card{
    background-color: #2c303a !important;
    height: 300px;
    margin-bottom: 30px;
    h3{
      font-size: 20px;
    }
    p{
      font-size: 14px;
    }
  &.light{
    background-color:rgb(218, 220, 222) !important;
    color: black !important;
    p{
      color: black !important;
    }
  }
  }

  .bg-overlay{
    background-color: #294266;
  opacity: .75;
  transition: background .3s,border-radius .3s,opacity .3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  &.light{
    background-color: gray;
  }
  }
  
}