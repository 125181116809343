
body{
color: #a4b4c3;
font-family: Outfit,sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 1.75;
overflow-x: hidden;}

// :root {
//     --primary: #514ce8;
//     --secondary: #191C24;
//     --light: #6C7293;
//     --dark: #000000;
//   