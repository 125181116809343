.btn-upload {
    // width: 10rem;
    background-color: #514ce8 !important;
    border-radius: 5px;
    margin: auto;
    border: none;
    padding: 8px 12px;
}

.btn-upload:hover {
    background-color: #4540c7 !important;
}
