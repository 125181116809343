.loan-form{
    background-color:  #030b15;
    min-height: 100vh;
    padding-top: 50px;
    padding-bottom: 50px;
    @media (max-width:992px) {
        padding-top: 120px;
    }
    &.light{
        background: rgb(218, 220, 222);
        color: #030b15;
    }
   
}