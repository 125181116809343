h6{
  margin-top: 40px;
}
.dropzone {
border: 2px solid #00cae2;
border-radius: 20px;
position: relative;
width: 160px;
height: 95  px;
margin-bottom: 15px;
display: flex;
justify-content: center;
cursor: pointer;
align-items: center;
p{
  padding: 10px;
  font-size: 13px ;
}
small{
  font-size: 15px;
}
  // dropzone message customization
.dz-message {
  font-size: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

dropzone message icon
.dz-message:before {
  content: "";
  //   background-image: url(str-replace(str-replace($download, 'currentColor', $primary), '#', '%23'));
  font-size: 50px;
  position: absolute;
  top: 14rem;
  width: 80px;
  height: 80px;
  display: inline-block;
  line-height: 1;
  z-index: 2;
  // color: $primary;
  text-indent: 0px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

for dropzone preview and remove icon
.dz-preview .dz-remove {
  font-size: 1rem;
  color: black;
  line-height: 2rem;

  &:before {
    content: "";
  //   background-image: url(str-replace(str-replace($remove, 'currentColor', $danger), '#', '%23'));
    display: inline-block;
    line-height: 1;
    z-index: 2;
    text-indent: 0px;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
  }

  &:hover {
    text-decoration: none;
  }
}
}

// For Small Screen drop Logo
@media (max-width: 576px) {
.dropzone {
  .dz-message {
    font-size: 1.5rem;
    &:before {
      top: 15rem;
      width: 50px;
      height: 50px;
    }
  }
}
}