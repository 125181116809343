
p{
  // color:white;
  span{
      border-bottom: 2px solid #4044e8;
  }
}
p .light{
  color: black;
}
.gray{
  color: white
}
.form-select{
color: white;
background-color: transparent;
border-bottom: 1px solid white !important;
border-radius: 0;
border: 0;
margin: 10px 0px;
&.light{
  color: black;
}
}
.col-lg-6.light>option {
  color: black;
}
