
#header{
  .dropdown-toggle::after {
      margin-left: 6px;
      vertical-align: middle;
      border: none;
      content: "\f107";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      transition: 0.5s;
    }
    .dropdown-toggle[aria-expanded=true]::after {
          transform: rotate(-180deg);
    }
  
  .droppp{
    height: 13vh;
    margin-top: 15px !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(168, 170, 171, 0.35) 0px -2px 6px 0px inset;
  }
  .menu-area {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
    position: absolute;
    top:0;
    // left:16%;
    z-index: 1;
    background: #030b15 ;
    width: 100% ;
    &.light{
      background: rgb(218, 220, 222);
      box-shadow: rgb(0 0 0 / 30%) 0px 0px 20px 0px;
    }
   
  }
  img {
    max-width: 70%;
    transition: all 0.3s ease-out 0s;
  }
  .navbar-nav{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .nav-link {
    display: block;
    a {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      padding: 10px 0;
      position: relative;
      text-transform: uppercase;
      text-decoration: none;
      margin-left: 18px;
   
      &:hover::before {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: left top;
        transform-origin: left top;
        border:2px solid#00c4f4 ;
      }
      &::before {
        background: #00c4f4;
        bottom: 0;
        content: "";
        height: 3px;
        left: 0;
        position: absolute;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: right top;
        transform-origin: right top;
        transition: -webkit-transform 0.4s
          cubic-bezier(0.74, 0.72, 0.27, 0.24);
        transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
        transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24),
          -webkit-transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
        width: 100%;
      }

      &.light{
          color: rgba(19, 3, 48, 0.87);
      }
    }
  }
  
  .header-action {
    @media screen and (max-width: 900px) {
        margin: auto;
        li{
          margin: 0;
        }
      }
    ul {
      align-items: center;
      display: flex;
      list-style-type:none; 
      @media screen and (max-width: 900px) {
        padding: 0;
        margin-top: 8px;
      }
      li {
        // margin-left: 55px;
        // padding-left: 55px;
        position: relative;
        &::before {
          background: #fff;
          // content: "";
          height: 30px;
          left: 0;
          opacity: 0.2;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          width: 2px;
          .mode-btn{
            margin-left: 200px;
          }
        }
      }
    }
  }
  .header-btn{
    margin-left: 15px;
  }
  
  .header-btn .btn {
    font-size: 14px;
    padding: 18px 30px;
    margin-left: 22px;
    margin-right: 22px;

    &::after {
      background: linear-gradient(90deg, #00c4f4, #040c15 49%, #00c4f4);
      border-radius: 70px;
      // bottom: 0;
      // content: "";
      // left: 0;
      // margin: -2px;
      position: absolute;
      right: 0;
      top: 0;
      transition: all 0.4s ease 0s;
      z-index: -1;
    }
  }
  
  .btn {
    background: #091828;
    // background: linear-gradient(var(130deg),
    //       #6138D8 25%,
    //       rgba(saturate(lighten(adjust-hue(#6138D8, 25deg), 5), 10), 0.9) 40%,
    //       rgba(saturate(lighten(adjust-hue(#6138D8, 25deg), 5), 10), 0.95) 65%,
    //       #6138D8 85%,
    //     ) no-repeat -1px 50% / 300% calc(100% + 2px);
      // background-clip: border-box;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 70px;
    box-shadow: 0 6px 22px rgba(6,34,51,.22);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: .5px;
    line-height: 1;
    margin-bottom: 0;
    padding: 25px 50px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: all .4s 0s;
    -webkit-user-select: none;
    user-select: none;
    -moz-user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    &:hover{
      background:transparent;
    }
  }
}
.mode-btn{
  border: none;
  background: #030b15;
  margin-left: 20px;
  margin: auto;
  margin-top: 6px;
  &.light{
    background:transparent   !important;
  }
}

