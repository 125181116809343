.light{
    color: black;
}
.top-line{
    height: 20px;
    background-color: rgb(218, 220, 222);
    width: 100%;
    position: absolute;
    &.light{
        background-color: #030b15;;
    }
}
.form{
    background-color:  #0b1d33;
    // padding:10px 20px 50px 0px;
    height: 100%;
    border-radius: 20px;
    margin-top: 190px;
    &.light{
        background-color: transparent;
    }
@media (max-width:992px) {
    height: 84%;
}
} 
.step-number{
    color: white;
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
    @media (max-width:992px) {
        font-size: 13px;
        margin-top: 30px;
    }
    &.light{
        color:black
    }
}
