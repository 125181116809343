.radio-button {
  border: 2px solid rgb(226, 209, 209);
  border-radius: 15px;
  height: 60px;
  background-color: white;
  padding: 18px 12px;
  color:black;
  font-size: 14px;
  margin: 0 0 15px;
  font-weight: 600;


  position: relative;
  &.not-active {
    background-color: transparent;
    color:white;
    font-weight: 600;
  }
  .form-check-input {
    background: white;
    color:black;
    border: 3px solid black;
  }
  .form-check-input:checked {
    background-color: black;
    border: 3px solid white;
  }
  .form-check-input:checked[type=radio] {
    background-image: none;
}

}

.radio-label {
  position: absolute;
  cursor: pointer;
  display: inline-block;
  float: right;
  // width: 100%;
  padding: 1px 0 0 5px;

}
.form-control {
  // border: 2px solid #4044e8;
  border-bottom: 1px solid white !important;
  border: none;
  border-radius: 0;
  // color: white;
  background: transparent;
  // backdrop-filter: blur(10px);
}
.form-floating > .form-control {
  height: 65px;
}

.form-floating > label {
  color:white ;
  
}
.form-floating.light > label {
  color:black ;
  
}
.form-control:focus {
  color: white;
  background-color: transparent; 
  border-color: black;
  outline: 0;
  box-shadow: none;
}


.form-control{ 
  color:white ;
}
.form-control.light{
  color: white !important;
}
.form-control:disabled{
  background-color: transparent !important;
}