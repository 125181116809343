.pb-130 {
    padding-bottom: 100px;
  }
  .about-area{
    // background: linear-gradient(0.23deg, #030b15 5.68%, rgba(3, 11, 21, 0.42) 81.9%);
    background: #030b15 ;
    
    &.light{
      background: rgb(218, 220, 222);
    }

  }
  .pt-130 {
    padding-top: 190px;
    @media (max-width:992px) {
      padding-top:80px;
    }
  }
  .about-img {
    padding-left: 0px;
    position: relative;
    text-align: center;
    img {
      width: 100%;
      transition: all .3s ease-out 0s;
      @media (max-width:992px) {
        width: 80%;
      }
    }
  }
 
  .about-content {
    margin-left: 70px;
    @media (max-width:450px) {
      padding: 10px;
      margin-left: 0px;
    }
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .title{
    &.light {
      color: #040c15;
   }
  }
  .title-2{
    &.light {
      color: #040c15;
   }
  }
  .section-title .sub-title {
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .09em;
    margin-bottom: 25px;
    padding: 0 15px;
    position: relative;
    text-transform: uppercase;
    &.light {
        color: black;
      }
  }
  .section-title .sub-title::before {
    background: #00c4f4;
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 10px;
  }
  .section-title .sub-title::after {
    left: auto;
    right: 0;
  }
  .section-title .sub-title::after, .section-title .sub-title::before {
    background: #00c4f4;
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 10px;
  }
  .section-title .title {
    font-size: 42px;
    letter-spacing: -.01em;
    margin-bottom: 0;
  }
  .section-title .title span {
    color: #00c4f4;
  }
  .about-content p {
    color: #a4b4c3;
    margin-bottom: 40px;
    width: 73%;
  }
    .about-us-btn{
    background: #091828;
      // background: linear-gradient(var(130deg),
      //       #6138D8 25%,
      //       rgba(saturate(lighten(adjust-hue(#6138D8, 25deg), 5), 10), 0.9) 40%,
      //       rgba(saturate(lighten(adjust-hue(#6138D8, 25deg), 5), 10), 0.95) 65%,
      //       #6138D8 85%,
      //     ) no-repeat -1px 50% / 300% calc(100% + 2px);
      // background-clip: border-box;
      background-clip: padding-box;
      border: 2px solid transparent;
      border-radius: 70px;
      box-shadow: 0 6px 22px rgba(6, 34, 51, .22);
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: .5px;
      line-height: 1;
      margin-bottom: 0;
      padding: 25px 50px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      touch-action: manipulation;
      transition: all .4s 0s;
      -webkit-user-select: none;
      user-select: none;
      -moz-user-select: none;
      vertical-align: middle;
      white-space: nowrap;
    
      &:hover {
        background: #123051;
      }
    }
          
  
 
    .about-btn{
      width: 50% !important;
    }
