.client.light{
    .btn-pay{
        color: white;
    }
    .modal-body{
        background:#e6e7eb;
    }
}
.btn-pay{
    width: 6rem;
    background-color: #514ce8 !important;
    border-radius: 5px;
    display: block;
    margin: auto;
    border: none;
}
.btn-pay:hover{
    background-color: #4540c7 !important;
}
.modal-body{
    background: black ;
    .container{
        padding-top: 0 !important;
    }
    .client-data{
        min-height: 200px;
    }
}
.modal-header{
    border: .5px solid rgb(160, 160, 160);
    border-top: 0;
    border-left: 0;
    border-right: 0;
}
.modal-title{
    color: #514ce8 !important;
}

.email-phoneNumber{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 30px;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        gap: 0;
    }
}
