.pb-130 {
    padding-bottom: 130px;
  }
  .choose-area{
    background-color: #030b15;

    &.light{
      background: rgb(218, 220, 222);
    }
  }

  .choose-area .row {
    margin: 0 -10px;
  }
  .choose-area .row [class*="col-"] {
    padding: 0 10px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
  .section-title .sub-title {
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .09em;
    margin-bottom: 25px;
    padding: 0 15px;
    position: relative;
    text-transform: uppercase;

    &.light {
        color: #030b15;
      }
  }
  .title{
    &.light {
        color: #030b15;
      }
  }
  .section-title .sub-title::before {
    background: #00c4f4;
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 10px;
  }
  .section-title .sub-title::after {
    left: auto;
    right: 0;
  }
  .section-title .sub-title::after, .section-title .sub-title::before {
    background: #00c4f4;
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 10px;
  }
  .section-title .title {
    font-size: 42px;
    letter-spacing: -.01em;
    margin-bottom: 0;
  }
  .section-title .title span {
    color: #00c4f4;
  }
  .choose-item {
    background: #030b15;
    border: 1px solid #121a23;
    border-radius: 15px;
    // min-height: 382px;
    padding:30px;
    transition: .3s ease-in-out;
    margin-bottom: 10px;
    &.light {
        background: rgb(207, 208, 212);
        box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
        border: 1px solid #c3cbd4;
      }
      @media (max-width:450px) {
        padding: 10px;
      }
  }
  .text{
    &.light{
      color: #030b15;
    }
  }
  .choose-icon {
    align-items: center;
    background: hsla(0,0%,100%,.07);
    border: 4px solid hsla(0,0%,100%,.06);
    border-radius: 50%;
    display: flex;
    height: 80px;
    justify-content: center;
    margin-bottom: 20px;
    transition: .3s ease-in-out;
    width: 80px;
    
    &.ligth{
      border: 4px solid rgb(67, 67, 67);
    }
  }
  img {
    max-width: 100%;
    transition: all .3s ease-out 0s;
  }
  .choose-item:hover .choose-icon {
    border-color: #00c4f4;
  }
  .choose-content a{
    text-decoration: none;
  }
  .choose-content .title {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.27;
    margin-bottom: 16px;
    text-transform: none;
  }
  .choose-content p {
    color: #a4b4c3;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0;
  }