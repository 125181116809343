

    .next-button{
        background-color: rgb(218, 220, 222) !important;
        border-radius: 15px !important;    
        color:black !important;
   
        padding: 17px 40px !important;
        &.light{
            color:white !important;
            background-color: #040e18 !important;
        }
    }
.prev{
    background-color: #4044e8 !important;
}
.btn:disabled{
    opacity: 1 !important;
}