.pb-110 {
  padding-bottom: 110px;
}
.pt-70 {
  padding-top: 70px;
}
.contact-area {
  background-color: #030b15;

  &.light {
    background: rgb(218, 220, 222);
  }
}
.mb-70 {
  margin-bottom: 70px;
}
.section-title .sub-title {
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.09em;
  margin-bottom: 25px;
  padding: 0 15px;
  position: relative;
  text-transform: uppercase;
  &.light {
    color: #030b15;
  }
}
.text {
  display: inline;
  &.light {
    color: black;
  }
}
.inner-text {
  &.light {
    color: black;
  }
}
.section-title .sub-title::before {
  background: #00c4f4;
  border-radius: 50%;
  content: "";
  height: 10px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 10px;
}
.section-title .sub-title::after {
  left: auto;
  right: 0;
}
.section-title .sub-title::after,
.section-title .sub-title::before {
  background: #00c4f4;
  border-radius: 50%;
  content: "";
  height: 10px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 10px;
}
.section-title .title {
  font-size: 42px;
  letter-spacing: -0.01em;
  margin-bottom: 0;
}
.section-title .title span {
  color: #00c4f4;
}
.contact-info-wrap {
  margin-bottom: 50px;
}
.contact-info-item {
  margin-bottom: 30px;
  position: relative;
  text-align: center;
}
.contact-info-item-last {
  margin-bottom: 30px;
  position: relative;
  text-align: center;
}
.contact-info-item {
  &::before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHoAAAAJCAYAAAD97ziLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAItSURBVHgB7ZYxbNNAFIb/cxUIbgeP3fCYkbGj2RgzMtYboyVoFaZ6K0qQmpHtMjJ27EbHbnRkw2zZyBIIlPp4f3IWqdX6bFFVspRfermL/b3z3b17dwdYGWMisUSsLxZio1ZJYvZJ7KvYfhV0YkEGOrUOR9ioNTI3xfj1y8CR2Lj0LBD7zCxHC5WmupukOmji8xA8+1WXJ9uEN7dLF7uzYvSlfK6UykqOEfsnz/t3NT4Y6cgo7MEgezLHaZrGC1To7XvdywE5GrD4PcdknMazKp6T82gb+6xvKZwdv4m/wCH6PN7BK2XQzT2cDV/HFy6fw5FOPCDgON4dxhMXPxjqF5Bxy1hm/hwfXOPmPEkRka8zbs6TzNFL1pXCxzrjZlQrXsfeqrGbQbbPzqV4impFnFAF9OY+nCvc5Ngjz0ntbuOZiydDlpYbN0/5PkJ+g3Wvhs9BqneXQaYUwlpZxMXN9sXv1w7CGnyv4Du+m7/Girfq4R60DDS36vILm9HfKr0lA1hwpV51ULmql5zBv5X5Z+VbqTVGFtMUNfSjg4z9sf1zZsIojafS+NTyF67stDrnD7/zc8vdr/walwUv85S5eO8Kl7JTLmjy1zmGCmVY7dYT2AuYXn9rz2ge6M6MGBzrsMlZQv5gqHfr8smJDprwhZr0afmdhmdu0/abnrn/eUZ/t5fqoAwVN+3U3sBZT7BRa2RjRo1v26HVGhhKwQwmdCrpPsNGrVER3Lvi9hfO93fVr8744QAAAABJRU5ErkJggg==);
    content: "";
    height: 9px;
    position: absolute;
    right: -61px;
    top: 44px;
    width: 122px;

    @media screen and (max-width: 1100px) {
      right: 0;
    }
  
  }
}



.contact-info-item .icon {
  align-items: center;
  background: transparent;
  border: 1px solid hsla(0, 0%, 100%, 0.07);
  border-radius: 50%;
  color: #ff9700;
  display: flex;
  font-size: 28px;
  height: 107px;
  justify-content: center;
  margin: 0 auto 10px;
  position: relative;
  width: 107px;
  z-index: 0;
}
.contact-info-item-last .icon {
  align-items: center;
  background: transparent;
  border: 1px solid hsla(0, 0%, 100%, 0.07);
  border-radius: 50%;
  color: #ff9700;
  display: flex;
  font-size: 28px;
  height: 107px;
  justify-content: center;
  margin: 0 auto 10px;
  position: relative;
  width: 107px;
  z-index: 0;
}
.contact-info-item .icon-background {
  background: #0b1d33;
  border-radius: 50%;
  height: 83px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 83px;
  z-index: -1;
  &.light {
    background: rgb(207, 208, 212);
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
      rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  }
}
.contact-info-item-last .icon-background {
  background: #0b1d33;
  border-radius: 50%;
  height: 83px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 83px;
  z-index: -1;
  &.light {
    background: rgb(207, 208, 212);
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
      rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  }
}
.contact-info-item .icon-background::before {
  background: transparent;
  border: 3px solid #ff9700;
  border-radius: 50%;
  content: "";
  height: 83px;
  left: 0;
  position: absolute;
  top: 0;
  width: 83px;
}
.contact-info-item-last .icon-background::before {
  background: transparent;
  border: 3px solid #ff9700;
  border-radius: 50%;
  content: "";
  height: 83px;
  left: 0;
  position: absolute;
  top: 0;
  width: 83px;
}
.contact-info-item-last .icon-background {
  background: #0b1d33;
  border-radius: 50%;
  height: 83px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 83px;
  z-index: -1;
  &.light {
    background: rgb(207, 208, 212);
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
      rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  }
}
.fa-envelope::before {
  content: "\f0e0";
}
.inner-text {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
}
.fa-phone::before {
  content: "\f095";
}
.fa-map-marker-alt::before {
  content: "\f3c5";
}
.contact-form-wrap {
  background-image: url(https://bigtech-react.netlify.app/static/media/contact_bg.ddd671e5f0c89319f016.png);

  background-position: 50%;
  background-size: cover;
  padding: 80px;
  &.light {
    background-image: none;
    // border: 1px solid black;
    border-radius: 20px;
    box-shadow: rgb(29, 29, 29, 40%) 0px 0px 16px;
  }
}
.contact-form-wrap .form-grp {
  margin-bottom: 30px;
}
.contact-form-wrap {
  .form-grp {
    input {
      background: #0b1d33;
      border: 1px solid hsla(0, 0%, 100%, 0.07);
      border-radius: 5px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      height: 65px;
      padding: 18px 20px;
      width: 100%;
    }
  }
  &.light {
    .form-grp {
      input {
        background-color: transparent;
        border: 1px solid black;
        color: black;
      }
    }
  }
}
.contact-form-wrap {
  .form-grp {
    textarea {
      background: #0b1d33;
      border: 1px solid hsla(0, 0%, 100%, 0.07);
      border-radius: 5px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      // height: 65px;
      padding: 18px 20px;
      width: 100%;
      &::placeholder {
          color: white;}
    }
    input{
      &::placeholder {
          color: white;
        }
    }
  }
  &.light {
    .form-grp {
      textarea {
        background-color: transparent;
        border: 1px solid black;
        color: black;
        &::placeholder {
            color: black;
          }
      }
      input{
        &::placeholder {
            color: black;
          }
      }
    }
  }
}
.contact-form-wrap .submit-btn .btn {
  background: #0b1d33;
  border-color: #00c4f4;
}
.btn::after {
  background: linear-gradient(90deg, #00c4f4, #040c15 49%, #00c4f4);
  border-radius: 70px;
  bottom: 0;
  content: "";
  left: 0;
  margin: -2px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.4s ease 0s;
  z-index: -1;
}
